import React from 'react';

import ImageSac from '../../../../images/photos/Image-fac.png';

import './style.css';

function Sac() {
  return(
    <>
    <section id="sac" className="sac">
      <div className="sac-image">
        <h1>FAÇA PARTE DO<br /> BANCO DO FUTURO</h1>
        <img src={ImageSac} alt=""/>
      </div>
      <div className="sac-information">
        <p className="information-title">Ainda tem alguma dúvida? Entre em contado com nosso <strong> SAC.</strong></p>
        <p>Telefone Local (Capitais e Regiões Metropolitanas): <strong>+55 061 93300-3850</strong></p>
        {/* <p>Telefone Local (Capitais e Regiões Metropolitanas): <strong>0800 212 0022</strong></p>
        <p className="ultimo-numero"><strong>+55 71 2132-6749</strong></p> */}
      </div>
    </section>
    <section className="sac-mobile">
      <div>
        <h1>FAÇA PARTE DO BANCO DO FUTURO</h1>
        <img src={ImageSac} alt="" />
      </div>
    </section>
    </>
  );
}

export default Sac;
