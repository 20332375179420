import React from 'react';
import { Link } from 'react-router-dom';

import FacebookIcon from '../../images/icons/Facebook-icon.svg';
import InstagramIcon from '../../images/icons/Instagram-icon.svg';
import LogoIca from '../../images/icons/Logo-ica.svg';
import AppStore from '../../images/icons/Download-appstore.svg';
import PlayStore from '../../images/icons/Download-playstore.svg';

import './style.css';

function Footer() {
  return(
    <>
    <footer className="footer" >
      <div className='informations'>
      <div className="informations-footer">
        <div className="icons-pages">
          <Link><img src={FacebookIcon} alt=""/></Link>
          <Link><img src={InstagramIcon} alt=""/></Link>
          </div>
          <img style={{marginRight: '40px'}} src={LogoIca} alt=""/>
          <div className="information-footer">
            <h3>ICA BANK SOLUÇÕES FINANCEIRAS LTDA CNPJ: 37.468.454/0001-00</h3>
            <p>
            A ICA BANK é uma fintech que está devidamente homologada junto a Acesso Soluções de Pagamentos, regulamentada pelo BANCO CENTRAL. Conforme a resolução, as nossas operações no Brasil com a instituição Acesso Soluções (332), funciona da seguinte forma: Ao fazer uma transferência via TED, para uma conta ICA BANK, por exemplo, o banco escolhido deve ser o ACESSO SOLUÇÕES FINANCEIRAS, código bacen (332).<br />
            A regulação para Fintech no Brasil, dentre elas estão: BACEN Carta Circular n. 3978/2020 e BACEN Carta Circular n. 4001/2020.

            </p>
          </div>
        </div>
        <div className="line"></div>
        <p className="directs">IcaBank 2020©. Todos os direitos reservados. Consulte nossa Termos de Uso e Política de privacidade e Contrato de Serviços.</p>
      </div>
      <div className='links'>
        <h3>Links úteis</h3>
        <a href="https://icabank-site.s3.amazonaws.com/Pol%C3%ADticas+de+Privacidade.pdf" target="_blank" >
            Política de privacidade
          </a>
        <a href="https://icabank-pdf.s3.amazonaws.com/ICA+BANK+-+Pol%C3%ADtica+de+Preven%C3%A7%C3%A3o+%C3%A0+Lavagem+de+Dinheiro.docx.pdf" target="_blank" >
          Política de Prevenção à lavagem de dinheiro
        </a>
        <a href="https://icabank-pdf.s3.amazonaws.com/ICA+BANK+-+Pol%C3%ADtica+de+Preven%C3%A7%C3%A3o+de+Riscos+Operacionais+e+Liquidez.docx.pdf" target="_blank" >
          Política de Prevenção de riscos operacionais
        </a>
        <a href="https://icabank-pdf.s3.amazonaws.com/ICA+BANK+-+Pol%C3%ADtica+de+Seguran%C3%A7a+de+Informa%C3%A7%C3%A3o+e+Cibern%C3%A9tica.docx.pdf" target="_blank" >
          Política de Segurança da informação
        </a>
        <a href="https://icabank-pdf.s3.amazonaws.com/Manual+de+Compliance_v12.07.docx.pdf " target="_blank" >
          Manual de Compliance
        </a>
        <a href="https://icabank-pdf.s3.amazonaws.com/CARTA+DA+CEO.pdf" target="_blank" >
          Carta da CEO
        </a>
      </div>
      <div style={{marginTop: 20, marginLeft: 15}}>
      <div className="IconsDownload">
        <a href="https://links.icamais.com/?fbclid=PAAab4FaVCXiJZiAvzNRmmFZFid46ZDpJJlBVlrsZOg3U9xbfITUTe3B_ajh8">
          <img src={AppStore} alt=""/>
        </a>
        <a href="https://links.icamais.com/?fbclid=PAAab4FaVCXiJZiAvzNRmmFZFid46ZDpJJlBVlrsZOg3U9xbfITUTe3B_ajh8">
          <img src={PlayStore} alt=""/>
        </a>
      </div>
      </div>
    </footer>
    
    
    
    <footer className="footer-mobile">
      <div className="footer-sac-mobile">
        <p>Ainda tem alguma dúvida? Entre em contado com nosso <green>SAC</green>.</p>
        <p>Telefone Local (Capitais e Regiões Metropolitanas): <br />
        <h5><green>+55 061 93300-3850</green></h5></p>
        
      </div>
      <div className="footer-mobile-infos">
        <h2>ICA BANK SOLUÇÕES FINANCEIRAS LTDA CNPJ: 37.468.454/0001-00</h2>
        <p>
          A ICA BANK é uma fintech que está devidamente homologada junto a Acesso Soluções de Pagamentos, regulamentada pelo BANCO CENTRAL. Conforme a resolução, as nossas operações no Brasil com a instituição Acesso Soluções (332), funciona da seguinte forma: Ao fazer uma transferência via TED, para uma conta ICA BANK, por exemplo, o banco escolhido deve ser o ACESSO SOLUÇÕES FINANCEIRAS, código bacen (332).<br />
          A regulação para Fintech no Brasil, dentre elas estão: BACEN Carta Circular n. 3978/2020 e BACEN Carta Circular n. 4001/2020.
        </p>
        <div className='links'>
          <p className='link-p'>Links úteis</p>
          <a href="https://icabank-site.s3.amazonaws.com/Pol%C3%ADticas+de+Privacidade.pdf" target="_blank" >
            Política de privacidade
          </a>
          <a href="https://icabank-pdf.s3.amazonaws.com/ICA+BANK+-+Pol%C3%ADtica+de+Preven%C3%A7%C3%A3o+%C3%A0+Lavagem+de+Dinheiro.docx.pdf" target="_blank" >
            Política de Prevenção à lavagem de dinheiro
          </a>
          <a href="https://icabank-pdf.s3.amazonaws.com/ICA+BANK+-+Pol%C3%ADtica+de+Preven%C3%A7%C3%A3o+de+Riscos+Operacionais+e+Liquidez.docx.pdf" target="_blank" >
            Política de Prevenção de riscos operacionais
          </a>
          <a href="https://icabank-pdf.s3.amazonaws.com/ICA+BANK+-+Pol%C3%ADtica+de+Seguran%C3%A7a+de+Informa%C3%A7%C3%A3o+e+Cibern%C3%A9tica.docx.pdf" target="_blank" >
            Política de Segurança da informação
          </a>
          <a href="https://icabank-pdf.s3.amazonaws.com/Manual+de+Compliance_v12.07.docx.pdf " target="_blank" >
            Manual de Compliance
          </a>
          <a href="https://icabank-pdf.s3.amazonaws.com/CARTA+DA+CEO.pdf" target="_blank" >
            Carta da CEO
          </a>
        </div>
      </div>
        <div className='line-mobile'></div>
      <div>
        
        <h4>IcaBank 2025©. Todos os direitos reservados. Consulte nossa Termos de Uso e Política de privacidade e Contrato de Serviços.</h4>
        <div className="download">
          <a href="https://links.icamais.com/?fbclid=PAAab4FaVCXiJZiAvzNRmmFZFid46ZDpJJlBVlrsZOg3U9xbfITUTe3B_ajh8">
            <img src={AppStore} alt="" />
          </a>
          <a href="https://links.icamais.com/?fbclid=PAAab4FaVCXiJZiAvzNRmmFZFid46ZDpJJlBVlrsZOg3U9xbfITUTe3B_ajh8">
          <img src={PlayStore} alt="" />
          </a>
        </div>
      </div>
      
    </footer>
    </>
  );
}

export default Footer;
